import React, { Component } from 'react';
import CopyButton from './components/buttons/CopyButton';
import './App.css';

class App extends Component {
  sourceRef = React.createRef();
  outputRef = React.createRef();

  state = {
    source: '',
    output: '',
    tabWidth: 2,
  };

  onInputChange = (evt) => {
    this.setState({
      [evt.target.name]: evt.target.value,
    });
  }

  onParsePrettifyClick = () => {
    const {
      source,
    } = this.state;

    try {
      let sourceParsed = JSON.parse(source);

      if (typeof sourceParsed === 'string') {
        sourceParsed = JSON.parse(sourceParsed);
      }

      this.setState({
        output: JSON.stringify(sourceParsed, null, 2),
      });
    }
    catch (e) {
      console.error(e);
    }
  }

  render() {
    const {
      source,
      output,
    } = this.state;

    return (
      <div className="App">
        <header className="App-header">
          <h1>Pretty Parse</h1>
          <div className="App-header__actions">
            <button
              type="button"
              disabled={source === ''}
              onClick={this.onParsePrettifyClick}
            >Parse &amp; Prettify</button>
          </div>
        </header>
        <section className="App-main">
          <div className="App-main__textarea">
            <textarea
              ref={this.sourceRef}
              id="source-input"
              name="source"
              value={source}
              onChange={this.onInputChange}
            />
            <CopyButton
              className="App-main__copy-button"
              target="#source-input"
            />
          </div>
          <div className="App-main__textarea">
            <textarea
              ref={this.outputRef}
              id="output-input"
              name="output"
              value={output}
              onChange={this.onInputChange}
            />
            <CopyButton
              className="App-main__copy-button"
              target="#output-input"
            />
          </div>
        </section>
        <footer className="App-footer">
          <p>Built by <a href="https://www.ryanhefner.com">Ryan Hefner</a></p>
          <p><a href="https://twitter.com/ryanhefner">@ryanhefner</a></p>
        </footer>
      </div>
    );
  }
}

export default App;
