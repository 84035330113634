import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Clipboard from 'clipboard';
import './CopyButton.css';

class CopyButton extends Component {
  constructor(props) {
    super(props);

    this.state = {
      copied: false,
    };

    this.copyButton = React.createRef();

    this.onCopySuccess = this.onCopySuccess.bind(this);
  }

  componentDidMount() {
    const {
      textCallback,
    } = this.props;

    this.clipboard = new Clipboard(this.copyButton.current, {
      text: textCallback,
    });

    this.clipboard.on('success', this.onCopySuccess);
  }

  componentWillUnmount() {
    if (this.clipboard) {
      this.clipboard.destroy();
    }
  }

  onCopySuccess() {
    const {
      notificationDelay,
    } = this.props;

    this.setState({
      copied: true,
    });

    const selection = window.getSelection();
    selection.collapseToEnd();

    setTimeout(() => {
      this.setState({
        copied: false,
      });
    }, notificationDelay);
  }

  render() {
    const {
      className,
      target,
    } = this.props;

    const {
      copied,
    } = this.state;

    return (
      <button
        className={`CopyButton ${className || ''}`}
        data-clipboard-target={target}
        ref={this.copyButton}
      >
        {copied ? 'Copied!' : 'Copy'}
      </button>
    );
  }
}

CopyButton.propTypes = {
  notificationDelay: PropTypes.number,
  target: PropTypes.string,
  textCallback: PropTypes.func,
};

CopyButton.defaultProps = {
  notificationDelay: 2500,
};

export default CopyButton;
